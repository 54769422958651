const confetti = require('canvas-confetti');

(($) => {
    /*
        Hier kommt das Javascript der Seite rein
        Bitte vervollständigen
        Wenn du fertig bist, dann entferne bitte alle Kommentare die mit // beginnen
        Weiter bitte alle Funktionen mit JSDoc Kommentaren versehen, die dokumentieren,
        was die Funktion so tut. (Siehe Funktion "fetchQuestions")
    */

    // Zunächst müssen alle CSS Selektoren der einzelnen Elemente bestimmt werden
    let viewStartSelector = "#view_start"; // Selector für die Startansicht
    let viewFirstQuestionSelector = "#view_firstquestion"; // Selector für Ansicht der ersten Frage
    let viewQuestionsSelector = "#view_questions"; // Selector für die Ansicht der weiteren Fragen
    let viewExplanationSelector = "#view_explanation";  // Selector für die Ansicht der Erklärung
    let startButtonSelector = "#startbutton";
    let explanationStartButtonSelector = "#view_explanation__startbutton";
    let firstQuestionStartButtonSelector = "#view_firstquestion_start_button";
    let menubarSelector = "#menubar";
    let menubarRightSelector = ".menu-right";
    let questionContainerSelector = "#questioncontainer";
    let answerSelector = ".answer";
    let quizmasterSelector = "#view_questions__quizmaster";
    let quizmasterCorrectAnswerSelector = "#quizmaster_correctAnswer";
    let quizmasterWrongAnswerSelector = "#quizmaster_wrongAnswer";
    let firstQuestionQuizmaster = "#view_firstquestion__quizmaster"
    let scoreboxSelector = "#view_question__scorebox";
    let additionalInformationSelector = "#quizmaster_correctAnswerText";
    let nextQuestionSelector = "#next_question_button";
    let homeSelector = "#homebutton";
    let viewQuestionsRestartSelector = "#view_questions_restart_button";
    let viewFirstQuestionRestartSelector = "#view_firstquestion_restart_button";
    let volumeSelector = "#volumebutton";
    let fiftyFiftySelector = "#fiftyFiftyJoker";
    let colleagueButtonSelector = "#colleagueJokerButton";
    let colleagueSelector = "#colleague_joker";
    let expertJokerSelector = "#expertJoker";
    let quizmasterExpertSelector = "#quizmaster_expert";
    let quizmasterExpertTextSelector = "#quizmaster_expertText";
    let finalScoreBoxSelector = "#final_score_box";
    let finalScoreSelector = "#final_score";
    let firstQuestionAnswer = ".firstquestion_answer";
    let yammerButtonSelector = "#yj-share-button";

    let questionResponse;
    let currentQuestion = 0;
    let selectedAnswers = [];

    // Da wir die Anforderung nach Sounds haben müssen wir hier noch die Soundfiles bennen
    let soundFiles = [
        {
            name: "correctAnswer",
            path: "assets/sounds/mixkit-achievement-bell-600.mp3"
        },
        {
            name: "backgroundMusic",
            path: "assets/sounds/Motivational.mp3"
        },
        {
            name: "gameover",
            path: "assets/sounds/Game_Show_Light_Orchestrals_Stab.mp3"

        },
        {
            name: "win",
            path: "assets/sounds/mixkit-cheering-crowd-loud-whistle-610.mp3"

        },
        {
            name: "click",
            path: "assets/sounds/mixkit-cool-interface-click-tone-2568.mp3"

        }
    ];

    // In diesem Array werden dann Audio-Objekte hinterlegt
    let gameAudio = [];

    // In modernem JavaScript können funktionen folgendermaßen deklariert werden
    //      let someFunction = () => {}     ist das gleiche wie:    function someFunction() {}
    // Oder mit Parametern:
    //      let someFunction = (param1, param2) => param1 + param2;
    // Entspricht:
    //      let someFunction = (param1, param2) => { return param1 + param2; }
    // ist das gleiche wie:
    //      function someFunction(param1, param2) { return param1 + param2; }
    //
    // Die folgende Funktion holt alle Fragen aus einer JSON-Datei (https://wiki.selfhtml.org/wiki/JSON)
    // mittels AJAX (https://www.w3schools.com/js/js_ajax_intro.asp) vom Server
    // Die Methode wurde absichtlich nicht mit jQuery implementiert, um das Konzept zu erläutern

    /***
     * Methode, um alle Fragen als JSON vom server zu holen
     *   
     * @param { Function } successCallback      
     * @param { Function } errorCallback     Eine Funktion die aufgerufen wird, falls es zu einem Fehler kommt
     * 
     * @returns { void }
    ***/
    let fetchQuestions = (successCallback, errorCallback) => {
        let xhr = new XMLHttpRequest(); // Das XMLHttpRequest-Objekt stellt uns Methoden für HTTP Anfragen bereit
        let result;
        // mit dem Event-Handler onreadystatechange können wir überwachen, ob Daten empfangen wurden
        // Genauer, ob sich der Status des Requests geändert hat
        xhr.onreadystatechange = () => {

            if (xhr.readyState === 4) // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
            {
                if (xhr.status == 200) // https://developer.mozilla.org/de/docs/Web/HTTP/Status
                {
                    result = JSON.parse(xhr.responseText);
                    successCallback(result);
                }
                else {
                    // Nicht erfolgreich?
                    // Dann errorCallback aufrufen
                    errorCallback();
                }
            }
        }
        // Als nächstes muss der Request (https://wiki.selfhtml.org/wiki/HTTP/Einsteiger-Tutorial) vorbereitet
        // und die Verbindung geöffnet werden
        xhr.open("GET", "assets/question-data.json");
        // Und zu guter letzt wird der Request an den Server geschickt
        xhr.send();
    }

    /**
     * Lädt die Audiodateien, die im Objekt übergeben werden
     * 
     * @param { Array } soundFiles Beinhaltet die Pfade zu den einzelnen Audiodateien
     * 
     * @returns { Array } todo
     */
    let loadSounds = (soundFiles) => {
        let resultArray = [];
        for (let sound of soundFiles) {
            let audio = new Audio(sound.path);

            resultArray.push({ name: sound.name, path: sound.path, audio: audio });

            // audio.addEventListener("canplaythrough", event => {
            //     resultArray.push({ name: sound.name, path: sound.path, audio: audio });
            // });
        }
        return resultArray;
    }

    /**
     * Wechselt die Ansicht
     * 
     * @param { String } viewId  HTML-ID der View die angezeigt werden soll
     * 
     * @returns { void }
     */

    let switchToView = (viewId) => {
        getAudio("click").audio.play();
        let activeView = $('.view:not([style^="display: none"])');
        let view = $(viewId);

        activeView.fadeOut(function () {
            view.fadeIn(function () {
                if ($(viewFirstQuestionSelector).is(":visible")) {
                    $(menubarRightSelector).addClass("inactive");
                    $(menubarSelector).fadeIn();
                } else if ($(viewQuestionsSelector).is(":visible")) {
                    $(menubarRightSelector).removeClass("inactive");
                }
            });
        });
    }

    /**
     * Leert den Fragecontainer
     * 
     * @param { JQueryElement } questionContainer   jQuery-Element das die Fragen beinhaltet
     */
    let clearQuestion = (questionContainer) => {
        questionContainer.find(answerSelector).each(function () {
            $(this).removeClass('hideAnswerText');
            $(this).removeClass('notClickable');
            $(this).removeClass('text_small');
            $(this).removeClass('text_medium');
        });
    }

    /**
     * Überprüft, ob die ausgewählte Antwort korrekt war
     * 
     * @param { Object } questionData      Ein Objekt, dass die Daten der zu überprüfenden Frage enthält (Siehe JSON-Datei)
     * @param { Number } selectedAnswer    Eine Zahl, die den Index der Gewählten Antwort enthält
     * 
     * @returns { Boolean }     true, wenn die Antwort korrekt war, false wenn sie falsch war
     */
    let checkAnswer = (questionData, selectedAnswer) => {
        if ($(colleagueSelector).is(":visible")) {
            $(colleagueSelector).fadeOut();
        }

        $(answerSelector).each(function () {
            $(this).addClass('notClickable');
        });

        if (selectedAnswer === questionData.correctAnswerIndex) {
            return showCorrectAnswer(questionData, selectedAnswer);
        }
        else {
            return showWrongAnswer(questionData, selectedAnswer);
        }
    }

    let showCorrectAnswer = (questionData, selectedAnswer) => {
        $(answerSelector + "[data-answerindex=" + selectedAnswer + "]").addClass("correctAnswer");
        getAudio("correctAnswer").audio.play();

        if ($(quizmasterExpertSelector).is(":visible")) {
            $(quizmasterExpertSelector).fadeOut(function () {
                $(quizmasterCorrectAnswerSelector).fadeIn();
            });
        }
        else {
            $(quizmasterSelector).fadeOut(function () {
                $(quizmasterCorrectAnswerSelector).fadeIn();
            });
        }

        $(menubarRightSelector).fadeOut(function () {
            if (currentQuestion !== 14) {
                $(nextQuestionSelector).fadeIn();
            }
        });

        if (currentQuestion == 14) {
            lastAnswerCorrect();
        }

        return true;
    }

    let lastAnswerCorrect = () => {

        $(questionContainerSelector).fadeOut(function () {
            $('#congratulations').fadeIn();
        });
        $(scoreboxSelector).fadeOut();

        getAudio("backgroundMusic").audio.pause();
        getAudio("win").audio.play();

        let canvasElement = document.querySelector('#confetti_canvas');

        let myConfetti = confetti.create(canvasElement, {
            resize: true
        });

        myConfetti({
            particleCount: 150,
            ticks: 300,
            spread: 100
        });

    }

    let showWrongAnswer = (questionData, selectedAnswer) => {
        getAudio("backgroundMusic").audio.pause();

        $(answerSelector + "[data-answerindex=" + selectedAnswer + "]").addClass("wrongAnswer");
        $(scoreboxSelector).fadeOut(function () {
            $(quizmasterWrongAnswerSelector).fadeIn();
            getAudio("gameover").audio.play();
        });

        $(menubarRightSelector).fadeOut();

        if ($(quizmasterExpertSelector).is(":visible")) {
            $(quizmasterExpertSelector).fadeOut(function () {
                $(finalScoreBoxSelector).fadeIn();
            });
        }

        else {
            $(quizmasterSelector).fadeOut(function () {
                $(finalScoreBoxSelector).fadeIn();

                if (currentQuestion === 0) {
                    $(finalScoreSelector).html("0");
                }
                else {
                    let currentPoints = $(".score" + "[data-questionindex=" + (currentQuestion - 1) + "] .points");
                    $(finalScoreSelector).html(currentPoints.text());
                }
            });
        }
        return false;
    }

    /**
     * Zeigt die nächste Frage an
     * 
     * @param { JQueryElement } questionContainer     Das jQuery-Element, dass den Fragetext und die Antworten enthalten wird
     * @param { Object } questionData               Die Frage aus der JSON-Datei
     * 
     * @returns { void }
     */
    let nextQuestion = (questionContainer, questionData, currentIndex) => {
        clearQuestion(questionContainer);

        let question = questionContainer.find(".questiontext");
        let answerA = questionContainer.find(".answertextA");
        let answerB = questionContainer.find(".answertextB");
        let answerC = questionContainer.find(".answertextC");
        let answerD = questionContainer.find(".answertextD");

        question.html(questionData.questionText);
        answerA.html(questionData.answers[0]);
        answerB.html(questionData.answers[1]);
        answerC.html(questionData.answers[2]);
        answerD.html(questionData.answers[3]);

        $(quizmasterCorrectAnswerSelector).fadeOut(function () {
            $(quizmasterSelector).fadeIn();
            $(menubarRightSelector).fadeIn();
        });

        if (typeof questionData.answersCssClass !== 'undefined') {
            $(answerSelector).addClass(questionData.answersCssClass);
        }

        $(nextQuestionSelector).fadeOut();
        $(answerSelector).removeClass(["correctAnswer", "notClickable"]);
        switchPoints(currentIndex, $('#view_question__scorebox'));
    }

    /**
     * Entfernt zwei falsche Antworten, wie sie in den Fragedaten hinterlegt wurden
     * 
     * @param { Object } questionData   Objekt, dass die Frage beinhaltet 
     * @param { JQueryElement } questionContainer     jQuery-Element, dass die in questionData übergebene Frage enthält
     * 
     * @returns { void }
     */
    let fiftyFiftyJoker = (questionData, questionContainer) => {

        if (!$(menubarRightSelector).hasClass("inactive")) {
            getAudio("click").audio.play();

            $(fiftyFiftySelector).off('click');
            $(fiftyFiftySelector).addClass('jokerUsed');
            let fiftyfifty = questionData.jokers.fiftyfifty;

            var i = 0;
            questionContainer.find(answerSelector).each(function () {
                if (!fiftyfifty[i]) {
                    $(this).addClass('hideAnswerText');
                    $(this).addClass('notClickable');
                }
                i++;
            });
        }
    }

    /**
     * 
     * @param { Object } questionData               Objekt, dass die Daten der Frage enthält
     * @param { JQueryElement } questionContainer   JQuery-Element, dass zur Darstellung der Fragen dient
     * 
     * @returns { void }
     */
    let audienceJoker = (questionData, audienceContainer) => {
        if (!$(menubarRightSelector).hasClass("inactive")) {
            getAudio("click").audio.play();

            $(colleagueButtonSelector).off('click');
            $(colleagueButtonSelector).addClass('jokerUsed');

            if ($(quizmasterExpertSelector).is(":visible")) {
                $(quizmasterExpertSelector).fadeOut(function () {
                    $(quizmasterSelector).fadeIn();
                });

            }

            audienceContainer.fadeIn();

            $("#colleague_answerA").css({ "height": questionData.jokers.audience[0] + "%" });
            $("#colleague_answerB").css({ "height": questionData.jokers.audience[1] + "%" });
            $("#colleague_answerC").css({ "height": questionData.jokers.audience[2] + "%" });
            $("#colleague_answerD").css({ "height": questionData.jokers.audience[3] + "%" });
        }
    }

    /**
     * Führt den Expertenjoker aus
     * 
     * @param { Object } questionData      Die Daten zu der aktuellen Frage
     * @param { JQueryElement } expertContainer   Der Container in dem die Expertenantwort angezeigt werden soll
     * 
     * @returns { void }
     */
    let expertJoker = (questionData, expertContainer) => {
        if (!$(menubarRightSelector).hasClass("inactive")) {
            getAudio("click").audio.play();

            $(expertJokerSelector).off('click');
            $(expertJokerSelector).addClass('jokerUsed');

            if ($(colleagueSelector).is(":visible")) {
                $(colleagueSelector).fadeOut();
            }

            $(quizmasterSelector).fadeOut(function () {
                $(quizmasterExpertSelector).fadeIn();
            });

            expertContainer.html(questionData.jokers.expert);
        }
    }

    /**
     * Wechselt den Punktestand passend zur aktuellen Frage
     * 
     * @param { Object } lastQuestionData  Aktuelle Frage für die der Punktestand gesetzt werden soll
     * @param { Array } allQuestions      Array mit allen Fragen
     * @param { JQueryElement } pointsContainer   Container der die Punkte enthält
     */
    let switchPoints = (currentIndex, pointsContainer) => {
        pointsContainer.find('.score').removeClass('active');
        pointsContainer.find('.score[data-questionindex=' + currentIndex + ']').addClass('active');
    }

    /**
     * Zeigt die zusätzlichen Informationen zu einer Frage an, falls diese korrekt beantwortet wurde
     * 
     * @param { Object } questionData   Objekt mit den Daten der Frage
     * @param { JQueryElement } infoContainer         Der Container in dem die zusätzlichen Informationen angezeigt werden sollen
     */
    let showAdditionalInformation = (questionData, infoContainer) => {
        infoContainer.html(questionData.additionalInformation);

        if (currentQuestion === 1 || currentQuestion === 2 || currentQuestion === 3 || currentQuestion === 6 || currentQuestion === 11) {
            $(additionalInformationSelector).addClass('text_medium');
            $(additionalInformationSelector).removeClass('text_large');
        } else if (currentQuestion === 7 || currentQuestion === 9 || currentQuestion === 13) {
            $(additionalInformationSelector).addClass('text_large');
            $(additionalInformationSelector).removeClass('text_medium');
        }
        else if (currentQuestion === 14) {
            $(additionalInformationSelector).removeClass('text_large');
            $(additionalInformationSelector).addClass('text_medium');
        } else {
            $(additionalInformationSelector).removeClass('text_medium text_large');
        }
    }

    /**
     * Schaltet die Sounds ein bzw. aus
     * 
     * @param { Array } audio 
     */
    let toggleSounds = (audio) => {
        for (let sound of audio) {
            if ($(volumeSelector).hasClass('volumeOff')) {
                sound.audio.muted = true;
            } else {
                sound.audio.muted = false;
            }
        }
    }

    let answerClick = (questionData, answerContainer) => {
        if (!$(answerContainer).hasClass('notClickable')) {
            $(answerContainer).addClass('notClickable');

            let isAnswerCorrect = checkAnswer(questionData, answerContainer.data("answerindex"));
            if (isAnswerCorrect === true) {
                showAdditionalInformation(questionData, $(additionalInformationSelector));
            }
        }
    }

    let findInArray = (arr, callback) => {
        for (var i = 0; i < arr.length; i++) {
            var match = callback(arr[i]);
            if (match) {
                return arr[i];
            }
        }
    }

    let getAudio = (name) => {
        return findInArray(gameAudio, function (e) { return e.name === name });
    }

    let setfirstQuestionAnswer = (container) => {
        getAudio("click").audio.play();
        container.addClass('checkedAnswer');
        container.addClass('notClickable');

        var answerText = container.html();

        //Ermitteln wie viele Boxen sichtbar sind
        var visible_firstquestion_sortedanswers_count = $(".sortedanswer:visible").length;

        //Die nächste, noch nicht eingebelendete, Box einblenden (mit Text)
        var current_sortedanswer = $('.sortedanswer').eq(visible_firstquestion_sortedanswers_count);
        current_sortedanswer.html(answerText);
        current_sortedanswer.fadeIn();

        if (visible_firstquestion_sortedanswers_count !== 4) {
            selectedAnswers.push(container.find('strong').html());
        }

        checkFirstQuestion();
    }

    let checkFirstQuestion = () => {
        if ($(".sortedanswer:visible").length === 4) {
            if (JSON.stringify(selectedAnswers) === JSON.stringify(questionResponse.sortQuestion.correctOrder)) {
                $('.firstquestion_answer').addClass('correctAnswer');
                $(firstQuestionQuizmaster).fadeOut(function () {
                    $(firstQuestionStartButtonSelector).fadeIn();
                });
                getAudio("correctAnswer").audio.play();
            } else {

                $('.firstquestion_answer').addClass('wrongAnswer');
                $(firstQuestionQuizmaster).fadeOut(function () {
                    $('#firstquestion_quizmaster_wrongAnswer').fadeIn();
                    $('#firstquestion_final_score_box').fadeIn();
                });
                getAudio("backgroundMusic").audio.pause();
                getAudio("gameover").audio.play();
                $(viewFirstQuestionRestartSelector).on('click', function () {
                    getAudio("click").audio.play();
                    window.location.reload();
                })
            }
        }
    }


    // Nachfolgend die Kurzform von $(document).ready(function() { ... })
    // Diese anonyme Funktion wird aufgerufen, sobald das DOM (https://www.w3schools.com/js/js_htmldom.asp) vollständig
    // geladen wurde.
    // Erst dann sind alle HTML-Elemente vollständig geladen und man kann auf sie zugreifen
    $(() => {
        // Hier muss die Komposition der einzelnen Funktionsbausteine erfolgen
        gameAudio = loadSounds(soundFiles); // Sounds laden

        $(startButtonSelector).on('click', function () {
            switchToView(viewExplanationSelector);
        });

        $(explanationStartButtonSelector).on('click', function () {
            switchToView(viewFirstQuestionSelector);

        });

        $(homeSelector).on("click", function () {
            getAudio("click").audio.play();
            window.location.reload();
        });

        $(viewQuestionsRestartSelector).on("click", function () {
            getAudio("click").audio.play();
            window.location.reload();
        });

        $('#firstquestion_view_questions_restart_button').on("click", function () {
            getAudio("click").audio.play();
            window.location.reload();
        });

        $(volumeSelector).on("click", function () {
            $(volumeSelector).toggleClass('volumeOff');
            toggleSounds(gameAudio);
        });

        $(firstQuestionStartButtonSelector).on("click", function () {
            switchToView(viewQuestionsSelector);
            let backgroundAudio = getAudio("backgroundMusic");
            backgroundAudio.audio.loop = true;
            backgroundAudio.audio.play();
        });

        $(firstQuestionAnswer).on("click", function () {
            setfirstQuestionAnswer($(this));
        });

        $(yammerButtonSelector).on("click", function () {
            $.getScript("https://s0.assets-yammer.com/assets/platform_social_buttons.min.js").done(function (script, textStatus) {
                var options = {
                    //customButton : false, //false by default. Pass true if you are providing your own button to trigger the share popup
                    //classSelector: 'mybutton-css-class',//if customButton is true, you must pass the css class name of your button (so we can bind the click event for you)
                    defaultMessage: 'Ich liebe das BP Social Media Quiz. Probiere es auch aus!', //optionally pass a message to prepopulate your post
                    pageUrl: 'https://socialmediaquiz.meinbp.de/' //current browser url is used by default. You can pass your own url if you want to generate the OG object from a different URL.
                };
                yam.platform.yammerShare(options);

                if (!$('#share_button_activated').hasClass('notClickable')){
                    $('#share_button_activated').fadeIn();
                    $('#share_button_activated').addClass('notClickable');                    
                    setInterval(function() {
                        $('#share_button_activated').fadeOut();
                    }, 10000);     
                }                          
            });
        });
        

        let xhrSuccess = (response) => {
            questionResponse = response;
            nextQuestion($(questionContainerSelector), questionResponse.allQuestions[currentQuestion], currentQuestion);

            $(fiftyFiftySelector).on('click', function () {
                fiftyFiftyJoker(questionResponse.allQuestions[currentQuestion], $(questionContainerSelector));
            });

            $(expertJokerSelector).on('click', function () {
                expertJoker(questionResponse.allQuestions[currentQuestion], $(quizmasterExpertTextSelector));
            });

            $(colleagueButtonSelector).on('click', function () {
                audienceJoker(questionResponse.allQuestions[currentQuestion], $(colleagueSelector));
            });

            $(answerSelector).on('click', function () {
                answerClick(questionResponse.allQuestions[currentQuestion], $(this));
            });

            $(nextQuestionSelector).on('click', function () {
                getAudio("click").audio.play();

                currentQuestion++;
                nextQuestion($(questionContainerSelector), questionResponse.allQuestions[currentQuestion], currentQuestion);

            });
        }

        let xhrError = () => {
            console.log('Fehler :(');
        }

        fetchQuestions(xhrSuccess, xhrError);
    });
})(require("jquery"));